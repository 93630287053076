<template>
    <seccion-datos titulo="APP" class="card card-warning" @cambios="guardar()" :botonGuardar="permisomodif">
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" type="checkbox" id="appmovil" class="custom-control-input" v-model="usa_app_movil_operario" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="appmovil" class="custom-control-label">{{ $t('general.utilizaappmovil') }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" type="checkbox" id="baremoapp" class="custom-control-input" v-model="ocultar_baremo_app" v-bind:true-value="1" v-bind:false-value="0"> 
                        <label for="baremoapp" class="custom-control-label">{{ $t('general.ocultarbaremoapp') }}</label>
                    </div>   
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" type="checkbox" id="baremoprecio" class="custom-control-input" v-model="ocultar_precio_baremo_app_operario" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="baremoprecio" class="custom-control-label" >{{ $t('general.ocultarpreciobaremo') }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" type="checkbox" id="fichaje" class="custom-control-input" v-model="fichaje_operario" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="fichaje" class="custom-control-label">{{ $t('general.fichaje') }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" class="custom-control-input" type="checkbox" id="pas" v-model="plataforma_pas" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="pas" class="custom-control-label">{{ $t('general.plataformapasrga') }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input :disabled="!permisomodif" type="checkbox" id="asignar-correduria" class="custom-control-input" v-model="app_pwgs" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="asignar-correduria" class="custom-control-label">{{ $t('general.apppwgs') }}</label>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary " @click="enviarInfo()">{{ $t('general.enviaremailconfiguracionapp') }}</button>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario', 'permisomodif'],
    data() {
        return {
            usa_app_movil_operario: '',
            ocultar_baremo_app: '',
            ocultar_precio_baremo_app_operario: '',
            fichaje_operario: '',
            plataforma_pas: '',
            app_pwgs: '',
        }
    },
    methods: {
        cargarDatosOperario() {
            this.$store.dispatch('cambiarCargando', true);
            this.usa_app_movil_operario = this.operario.usa_app_movil_operario;
            this.ocultar_baremo_app = this.operario.ocultar_baremo_app;
            this.ocultar_precio_baremo_app_operario = this.operario.ocultar_precio_baremo_app_operario;
            this.fichaje_operario = this.operario.fichaje_operario;
            this.plataforma_pas = this.operario.plataforma_pas;
            this.app_pwgs = this.operario.app_pwgs;
            this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/operarios/:id */
            try {
                const api = new PwgsApi;
                const subidadatos = { usa_app_movil_operario: this.usa_app_movil_operario, ocultar_baremo_app: this.ocultar_baremo_app, ocultar_precio_baremo_app_operario: this.ocultar_precio_baremo_app_operario, fichaje_operario: this.fichaje_operario, plataforma_pas: this.plataforma_pas, app_pwgs: this.app_pwgs };
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('operarios/' + this.operario.id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async enviarInfo() { 
            /* put  modulos/pwgsapi/index.php/operarios/:id/enviar-configuracion-app */
            try {
                const api = new PwgsApi;
                const subidadatos = { usa_app_movil_operario: this.usa_app_movil_operario, ocultar_baremo_app: this.ocultar_baremo_app, ocultar_precio_baremo_app_operario: this.ocultar_precio_baremo_app_operario, fichaje_operario: this.fichaje_operario, plataforma_pas: this.plataforma_pas, app_pwgs: this.app_pwgs };
                await api.put('operarios/' + this.id + '/enviar-configuracion-app', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    mounted() {
        this.cargarDatosOperario();
    },
    watch: {
        operario() {
            this.cargarDatosOperario();
        }
    },
}
</script>
<style>
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7;
}
</style> 